@import "./project-variables.scss";

html, body, button, textarea, input, svg {
    font-family: 'Astra_Sans', sans-serif !important;
}

html, body {
    font-size: 16px;
}

.el-dialog__body, .el-popover{
    word-break: normal;
}

button i.fab, button i.fas {
    padding: 0 5px 0 0;
}

.el-dialog__body{
    padding-top:10px;
    padding-bottom:10px;
}

.el-menu--horizontal>.el-menu-item{
    height:40px;
    line-height:40px;
}
.el-menu.el-menu--horizontal {
    border-bottom: inherit;
}

.el-menu--horizontal>.el-submenu, .el-menu--horizontal>.el-submenu .el-submenu__title{
    height:40px;
    line-height:40px;
}

.el-table .cell{
    word-break: normal;
}




.el-tree-node__content:hover {
    background-color: #FFF9EE;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color: #ffe6c5;
}







.el-button{
    text-transform: uppercase;
    font-weight: bold;
    /*color: black;*/
}

.el-button.button-icon-green i{
    color: $--color-success;
}


//.el-pagination.is-background .el-pager li:not(.disabled).active{
//    color: $button-text-color
//}
//
//.el-button--primary.is-disabled, .el-button--primary.is-disabled:hover, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:active {
//    color: $button-disabled-text-color;
//}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

//
//.el-button.el-button--default{
//    background-color: #E4E6EC;
//    color: #999999;
//}
//.el-button.el-button--default:hover {
//    background-color: #FBECE5;
//    border-color: #FBECE5;
//    color: #E18250;
//}
//.el-button.el-button--default.is-disabled{
//    background-color: #F7F7F7;
//    border-color: #F7F7F7;
//    color: #B3B3B3;
//}
//
//.el-button.el-button--primary {
//    color: #404040;
//}
//.el-button.el-button--primary:hover {
//    background-color: #EE7918;
//    border-color: #EE7918;
//    color: white;
//}
//.el-button.el-button--primary.is-disabled{
//    background-color: #FEEAC1;
//    border-color: #FEEAC1;
//    color: #B3B3B3;
//}
//
//.el-button.el-button--text {
//    color: #999999;
//}
//.el-button.el-button--text:hover {
//    color: #E18250;
//}




.el-button.table-action-button {
    padding: 5px 5px;
}
.el-button.table-action-button i {
    padding: 0;
}





.el-form-item--small.el-form-item {
    margin-bottom: 10px;
}
.el-form-item.el-form-item--small label.el-form-item__label {
    padding:0;
}


.card-stages{
    font-size: 0.9rem;
}
.card-section{
    font-size: 1.1rem;

    .el-table{
        font-size: 0.8rem;
    }
}

.option-no-label{
    color: #b2b2b2;
    font-style: italic;
}


.el-header img{
    height:30px;
    padding-bottom: 4px !important;
}